import React from 'react'
import { graphql, Link } from 'gatsby'
import cx from 'classnames'
import SEO from '../components/seo'
import Circle from '../images/svgs/circle.inline.svg'
import Layout from '../components/layout'
import ProcessGallery from '../components/process-gallery'

// @TODO make something better thant this and move to utils
const humanUrl = url => {
  return url.replace(/^https?:\/\/(w{3}\.)?/gi, '').replace(/\/$/gi, '')
}

const videoEmbedUrl = url => {
  const urlParts = new URL(url)
  if (urlParts.host.includes('youtube')) {
    return `//www.youtube.com/embed/${urlParts.searchParams}`
  } else {
    const segments = urlParts.pathname.split('/')
    return `//player.vimeo.com/video/${segments[1]}?player_id=video&api=1`
  }
}

const ProjectPage = ({ data }) => {
  const page = data.craft.entries[0]
  const category = page.projectType[0]
  const moreProjects = data.craft.moreProjects
  const { launchDate, retiredDate } = data.projectDates
  const status = retiredDate ? 'Archived' : 'Active'
  const displayLogo =
    page.logo.length > 0
      ? page.logo[0].url
      : page.icon.length > 0
      ? page.icon[0].url
      : category.icon[0].url
  const backgroundColor = page.backgroundColor || category.backgroundColor
  const linkColor = page.linkColor || category.linkColor
  const textColor = page.textColor || category.textColor
  const accentColor = page.accentColor || category.accentColor

  const infoList = [
    {
      label: page.projectUrl ? 'Visit' : null,
      visitUrl: page.projectUrl,
      visitText: page.projectUrl
    },
    {
      label: page.twitterHandle ? 'Follow' : null,
      twitterHandle: page.twitterHandle
    },
    {
      label: category ? 'Type' : null,
      category: category.title
    },
    {
      label: launchDate ? 'Launched' : null,
      launchDate
    },
    {
      label: launchDate ? 'Status' : null,
      status
    }
  ]

  React.useEffect(() => {
    document.documentElement.style.setProperty(
      '--body-color',
      page.backgroundColor
    )
  }, [page.backgroundColor])

  return (
    <Layout backgroundColor={backgroundColor}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .text-block p { color: ${textColor}; }
            .text-block a { color: ${linkColor}; }
          `
        }}
      />
      <SEO title={page.title} />
      <h1 className="pt-80 px-20 text-center uppercase text-24 mb-24">
        <img className="block mx-auto" src={displayLogo} alt="" />
        <span style={{ color: textColor }}>{page.title}</span>
      </h1>

      <section className="flex justify-center mb-60">
        <ul
          className="list-none p-0 flex lg-d:flex-col lg-d:w-full lg:border-b border-t lg:border-r"
          style={{ borderColor: accentColor }}
        >
          {infoList.map((item, index) => {
            if (!item.label) return
            const itemProps = {
              className: 'block px-16 break-all',
              style: {
                color: linkColor
              }
            }
            return (
              <li
                className="lg:border-l lg-d:border-b lg-d:text-center lg-d:flex lg-d:items-center font-display uppercase leading-loose"
                style={{ borderColor: accentColor }}
                key={`info-list-${index}`}
              >
                {/* label */}
                <strong
                  className="block lg-d:w-1/5 lg-d:flex-shrink-0 lg-d:text-right lg:border-b text-10 px-8 text-center"
                  style={{ borderColor: accentColor, color: textColor }}
                >
                  {item.label}
                </strong>
                {/* items */}
                {item.visitUrl && item.visitText && (
                  <a
                    {...itemProps}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.visitUrl}
                  >
                    {humanUrl(item.visitText)}
                  </a>
                )}
                {item.twitterHandle && (
                  <a
                    {...itemProps}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://twitter.com/${item.twitterHandle}`}
                  >
                    @{item.twitterHandle}
                  </a>
                )}
                {item.category && <span {...itemProps}>{item.category}</span>}
                {item.launchDate && (
                  <span {...itemProps}>{item.launchDate}</span>
                )}
                {item.status && <span {...itemProps}>{item.status}</span>}
              </li>
            )
          })}
        </ul>
      </section>

      <div
        className="text-block text-innerp-24 px-8 mx-auto mb-60 max-w-740 text-center"
        dangerouslySetInnerHTML={{ __html: page.description }}
      />

      {page.examples.map(
        ({ __typename, text, image, caption, videoUrl, id }) => {
          // console.log(__typename, text, image, caption, videoUrl, id)
          if (__typename === 'Craft_examples_text_BlockType') {
            return (
              <div
                key={id}
                className="text-block mb-innerp-24 max-w-740 mx-auto my-40 px-24"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )
          } else if (__typename === 'Craft_examples_image_BlockType') {
            const img = image[0]

            if (!img) return

            return (
              <div key={id} className="relative max-w-1220 mx-auto my-40 px-24">
                <figure>
                  <img
                    className="relative block mx-auto z-10"
                    src={img.url}
                    alt=""
                  />

                  {img.caption && <figcaption>{img.caption}</figcaption>}

                  <Circle
                    className="absolute left-1/2 top-0 transform -translate-x-1/2 w-full h-full z-0"
                    style={{ fill: accentColor }}
                  />
                </figure>
              </div>
            )
          } else if (__typename === 'Craft_examples_video_BlockType') {
            return (
              <figure
                key={id}
                className="max-w-1220 mx-auto w-full my-40 px-24"
              >
                <div className="relative aspect-ratio-16x9">
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src={videoEmbedUrl(videoUrl)}
                  />
                </div>

                {caption && (
                  <figcaption
                    className="p-16"
                    style={{ backgroundColor: accentColor }}
                  >
                    <p
                      className="antialiased font-display"
                      style={{ color: textColor }}
                    >
                      {caption}
                    </p>
                  </figcaption>
                )}
              </figure>
            )
          }
        }
      )}

      <ProcessGallery
        items={page.process}
        backgroundColor={accentColor}
        textColor={textColor}
      />

      {page.relatedContent.length > 0 && (
        <section className="max-w-1220 mx-auto px-24 pb-40">
          <h2
            className="text-36 text-center uppercase mb-16"
            style={{ color: textColor }}
          >
            More about {page.title}
          </h2>

          <ul className="list-none p-0 text-center uppercase font-display text-18">
            {page.relatedContent.map(
              ({ contentTitle, contentUrl, sourceName }) => {
                if (contentTitle || contentUrl || sourceName) {
                  return (
                    <li key={contentTitle}>
                      <a
                        href={contentUrl}
                        style={{ color: linkColor }}
                        className="block py-16 no-underline"
                        rel="noopener noreferrer"
                      >
                        {contentTitle}
                        <span
                          className="block text-12 leading-loose"
                          style={{ color: textColor }}
                        >
                          via {sourceName}
                        </span>
                      </a>
                    </li>
                  )
                }
              }
            )}
          </ul>
        </section>
      )}

      <section className="max-w-1220 mx-auto px-24 pb-40">
        <h2
          className="text-36 text-center uppercase mb-16"
          style={{ color: textColor }}
        >
          More Pointless Projects
        </h2>

        <ul className="list-none p-0 flex flex-wrap font-display uppercase text-center font-bold text-18">
          {moreProjects.map(proj => {
            return (
              <li key={proj.id} className="w-1/2 sm:w-1/3">
                <Link
                  className="block no-underline py-16"
                  style={{ color: linkColor }}
                  to={`/${proj.slug}`}
                >
                  {proj.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </section>
    </Layout>
  )
}

export default ProjectPage

/**
 * @TODO
 *
 * - need to write something custom for `moreProjects` so it can be randomized or
 * so different projects show up each time
 *
 * - should probably break down this page into components and this query into fragments
 */
export const pageQuery = graphql`
  query($id: [Int], $slug: String) {
    projectDates(slug: { eq: $slug }) {
      launchDate
      expiryDate
    }
    craft {
      moreProjects: entries(limit: 12, section: "projects") {
        id
        title
        slug
      }
      entries(id: $id) {
        id
        title
        slug
        ... on Craft_projects_projects_Entry {
          description
          projectUrl
          twitterHandle
          backgroundColor
          linkColor
          textColor
          accentColor
          tagline
          icon {
            url
          }
          logo {
            url
          }
          relatedContent {
            ... on Craft_relatedContent_relatedContent_BlockType {
              contentUrl
              contentTitle
              sourceName
            }
          }
          examples {
            ... on Craft_examples_text_BlockType {
              id
              text
            }
            ... on Craft_examples_image_BlockType {
              id
              image {
                url
                ... on Craft_projects_Asset {
                  caption
                }
              }
            }
            ... on Craft_examples_video_BlockType {
              id
              caption
              videoUrl
            }
          }
          process {
            id
            url
            ... on Craft_projects_Asset {
              caption
            }
          }
          projectType {
            title
            slug
            ... on Craft_projects_Category {
              icon {
                url
              }
              backgroundColor
              textColor
              accentColor
              linkColor
            }
          }
        }
      }
    }
  }
`
