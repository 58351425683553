import React from 'react'
import cx from 'classnames'

const ProcessGalleryPhoto = ({
  item,
  className,
  colors: { backgroundColor, textColor }
}) => (
  <li className={cx('flex', className)}>
    <figure className="relative max-w-980 mx-auto">
      <img className="block w-full h-full object-cover" src={item.url} alt="" />
      {item.caption && (
        <figcaption
          className="absolute bottom-0 inset-x-0 p-16"
          style={{ backgroundColor }}
        >
          <p className="antialiased font-display" style={{ color: textColor }}>
            {item.caption}
          </p>
        </figcaption>
      )}
    </figure>
  </li>
)

const ProcessGallery = ({ items, backgroundColor, textColor }) => {
  return (
    <section className="mb-60">
      <ul className="list-none p-0 flex flex-wrap">
        {items.map((item, index) => {
          let className = 'w-full'

          // three items
          if (items.length === 3) {
            className = 'w-1/3'
          }

          // five items
          if (items.length === 5) {
            if (index < 2) {
              className = 'w-1/2'
            } else {
              className = 'w-1/3'
            }
          }

          // even number of items
          if (items.length % 2 === 0) {
            if (items.length === 2) {
              className = 'w-1/2'
            } else {
              className = 'w-1/4'
            }
          }

          return (
            <ProcessGalleryPhoto
              key={item.id}
              item={item}
              className={className}
              colors={{ backgroundColor, textColor }}
            />
          )
        })}
      </ul>
    </section>
  )
}

export default ProcessGallery
